<template>
  <div>
    <el-form
        ref="form"
        label-width="0"
        class="view pa24"
        label-position="left"
    >
      <el-form-item>
        <p style="color: #606266;font-family: Source Han Sans CN;font-size:26px;text-align: center;font-weight: bold;width: 70%;margin: 0 auto">
          {{ detailsDate.title }}</p>
      </el-form-item>
      <el-form-item style="margin-top: 30px">
        <div style="display: flex;justify-content: space-between;font-size: 18px">
          <div style="display: flex"><img src="../../assets/images/square_head.png"
                                          style="width: 30px;height: 30px;align-self: center" alt="">
            <p style="color: #606266;font-weight: bold;font-size: 18px;margin: 0 10px">{{ detailsDate.userName }}</p>
            <p style="border: 1px solid #A0A1A3;border-radius: 4px;font-size: 12px;color: #A0A1A3;height: 26px;line-height: 26px;padding: 0 5px;align-self: center">
              {{ detailsDate.cname ? detailsDate.cname : detailsDate.assName }}</p>
          </div>
          <div style="color: #606266;font-size: 14px;"><i class="el-icon-time"></i> {{ detailsDate.createTime }}</div>
          <div>
            <div style="display: flex;font-size: 14px">
              <div style="display: flex">
                <img src="../../assets/images/like.png"
                     style="width: 14px;height: 12px;align-self: center;margin-right: 2px" alt="">
                <p>{{ detailsDate.fcount }}</p>
              </div>
              <p style="padding-left: 30px"><i class="el-icon-view"></i> {{ detailsDate.bcount }}</p>
            </div>
          </div>
        </div>
      </el-form-item>
      <hr
          style="
          background-color: #e4e4e4;
          border: none;
          height: 1px;
          margin: 10px 0;
        "
      />

      <div style="display: flex;margin-top: 30px">
        <div style="border-right: 1px solid #e4e4e4;padding-right: 50px;padding-left: 20px;width: 100%">
          <el-form-item>
            <div style="display: flex">
              <div v-html="detailsDate.content"></div>
            </div>
          </el-form-item>
          <hr
              style="
          background-color: #e4e4e4;
          border: none;
          height: 1px;
          margin: 10px 0;
        "
          />
          <el-form-item>
            <p style="padding-left: 20px"><i class="el-icon-chat-dot-square"></i> 全部评论 ({{ detailsDate.dcount }})</p>
          </el-form-item>
          <div class="discuss_wrap" v-for="(item,index) in discussPageList" :key="index">
            <div class="discuss_title">
              <img :src="item.headImg" alt="">
              <div>
                <p style="font-weight: bold">{{ item.userName }}</p>
                <p>{{ item.createTime }}</p>
              </div>
            </div>
            <div class="discuss_button">
              <p>{{ item.content }}</p>
              <p @click="del(item.discussId)"><i class="el-icon-delete"></i> 删除评论</p>
            </div>
            <hr
                style="
          background-color: #e4e4e4;
          border: none;
          height: 1px;
          margin: 10px 0;
        "
            />
          </div>
          <div style="text-align: center">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-sizes="[3, 5, 10, 15]"
                :page-size="3"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
            </el-pagination>
          </div>
        </div>
        <div style="width: 110px">
          <div v-for="(item,index) in detailsDate.urlList" :key="index"
               style="padding-left: 30px;height: 80px;margin-bottom: 15px">
            <el-image
                style="width: 80px; height: 80px;border-radius: 10px"
                :src="item"
                :preview-src-list="detailsDate.urlList">
            </el-image>
          </div>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import {queryCircleDailyById, selectDiscussList, delDiscuss} from "@/api/square";

export default {
  name: "circleDetails",
  data() {
    return {
      currentPage: 1,
      pageNum: 1,
      pageSize: 10,
      detailsDate: {},
      dialogImageUrl: "",
      dialogVisible: false,
      isShow: false,
      discussPageList: [],
      total: 0, //总条数,
      circleDailyId: '',
    };
  },

  created() {
    let circleDailyId = this.$route.query.circleDailyId
    this.circleDailyId = circleDailyId
    this.getDetails(circleDailyId)
    this.getDiscussList(circleDailyId)
  },

  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.getDiscussList(this.circleDailyId);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getDiscussList(this.circleDailyId);
    },
    getDetails(circleDailyId) {
      let data = {
        circleDailyId: circleDailyId
      }
      queryCircleDailyById(data)
          .then(res => {
            let data = res.data
            data.createTime = this.renderTime(data.createTime)
            data.urlList = data.urlList.split(',')
            this.detailsDate = data
          })
          .catch(err => {
            console.log(err)
          })
    },
    renderTime(date) {
      var dateee = new Date(date).toJSON();
      return new Date(+new Date(dateee) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
    },
    getDiscussList(tableId) {
      let data = {
        tableId: tableId,
        associationId: localStorage.getItem("associationId"),
        type: 0,
        pageSize: this.pageSize,
        pageNum: this.currentPage,
      }
      selectDiscussList(data)
          .then(res => {
            const {total, list} = res.data.pageInfo;
            for (let i in list) {
              list[i].createTime = this.renderTime(list[i].createTime)
            }
            this.discussPageList = list;
            this.total = total;
          })
          .catch(err => {
            this.discussPageList = []
            console.log(err)
          })
    },
    del(val) {
      try {
        this.$confirm('此操作将删除该评论, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let data = {
            discussId: val
          }
          delDiscuss(data)
              .then(res => {
                this.getDiscussList(this.circleDailyId);
                this.$message({
                  type: 'success',
                  message: '删除成功!'
                });
              })
              .catch(err => {
                console.log(err)
              })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });

      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style>
.discuss_wrap {

}

.discuss_title {
  display: flex;
  padding-left: 20px;
}

.discuss_title > img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
}

.discuss_title > div {
  /*display: flex;*/
  /*flex-direction: column;*/
  /*font-size: 18px;*/
  /*align-self: center;*/
  margin-left: 10px;
}

.discuss_title > div > p:first-child {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #606266;
}

.discuss_title > div > p:nth-child(2) {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #8D8D8D;
}

.discuss_button {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  padding-left: 20px;
}

.discuss_button > p:first-child {
  margin-left: 40px;
  margin-right: 30px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #606266;
  flex: 1;
}

.discuss_button > p:nth-child(2) {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #606266;
}

</style>
